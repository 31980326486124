@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  font-family: "Montserrat", sans-serif;
}

input:not([type="file"]) {
  @apply !border-[#BDBDBD];
}

input:disabled {
  @apply !bg-gray-100;
}

.checkbox:disabled {
  @apply !border-gray-600;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.widthPDF {
  width: calc(100vw - 200px);
}

.no-tailwind {
  all: unset;
}

.dump-card-shadow {
  box-shadow: 0px 4px 8px 1px #00000014; box-shadow: 0px 0px 0px 1px #00000014;
}